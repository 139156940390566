$list-and-form-padding-tablet: 0 base-spacing(4) 0 base-spacing(4);
$list-item-number-width: 32px;
$list-item-number-margin-right: base-spacing(1);

.bsd-landing {
  @include content-styles;

  &-heading,
  &-text,
  &-list-item,
  &-label,
  &-label-text {
    color: $white;
  }

  &-heading {
    margin: 0 0 base-spacing(0.5) 0;
  }

  &-list {
    margin: base-spacing(1.5) 0 base-spacing(2);
    padding: 0;
    text-align: left;
    list-style-type: none;

    @include responsive($width-medium) {
      padding: $list-and-form-padding-tablet;
    }

    @include responsive($width-large) {
      padding: 0;
    }
  }

  &-list-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: base-spacing(0.75);

    @include responsive($width-medium) {
      align-items: center;
    }
  }

  &-list-item-number {
    width: base-spacing(1.5);
    margin: 0 $list-item-number-margin-right 0 0;
    padding: 1px 9px;
    border: 2px solid $white;
    border-radius: 50%;
  }

  &-form {
    align-items: flex-end;
    margin: 0 0 base-spacing(1.5);

    @include responsive($width-medium) {
      display: flex;
      padding: $list-and-form-padding-tablet;
    }

    @include responsive($width-large) {
      padding: 0 base-spacing(4) 0 ($list-item-number-width + $list-item-number-margin-right);
    }
  }

  .bsd-form-field {
    text-align: left;

    @include responsive($width-medium) {
      margin: 0 base-spacing(1) 0 0;
    }
  }

  .bsd-form-field-message {
    color: $white;
  }

  .bsd-btn--primary {
    padding: 18px base-spacing(1.5); // override to match the height of the input

    &-error {
      margin-bottom: base-spacing(1.5); // match height of error message including margin-top
    }
  }
}
